<template>
    <a-card :bordered="false">
      <div slot="extra" v-if="viewTab">
        <a-radio-group v-model="menuSelect" :defaultValue="menuSelect">
          <a-radio-button
            v-for="item in menuList"
            :value="item.value"
            :key="item.id"
            @click="menuChoose(item.value)"
            style="margin:0 10px 10px 0"
            >{{ item.description }}</a-radio-button>
        </a-radio-group>
      </div>
      <a-button type="primary" style="margin-bottom:20px;" @click="plusMenu"><a-icon type="plus"/> 上传资料</a-button>
      <a-input-search
        placeholder="请输入通知标题"
        style="display:block; float:right; width:50%;"
        v-model="searchParam.title"
        enter-button @search="onSearch" />
      <a-table
        :columns="column"
        :dataSource="menuDetailList"
        :pagination="pagination"
        rowKey="id"
        @change="handleTableChange"
      >
        <template v-for="col in ['name', 'content', 'type']" :slot="col" slot-scope="text">
          <template>{{ text }}</template>
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click="viewItem(record.id)">查看</a>
          <a-divider type="vertical" />
          <a @click="editItem(record.id)">编辑</a>
          <a-divider type="vertical" />
          <a @click="delItem(record.id, record.menu)">删除</a>
        </template>
      </a-table>
    </a-card>
</template>

<script>
import { getMenuList, getDocList, updataMenu, deleteDoc } from '@/api/basic/common'

export default {
  name: 'TableList',
  components: {
  },
  data () {
    return {
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '15', '20'],
        showTotal: total => `共有 ${total} 条数据`
      },
      nowPage: 1,
      viewTab: true,
      // create model
      visible: false,
      confirmLoading: false,
      mdl: null,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      menuParam: {
        menu: '资料类型'
      },
      searchParam: {
        title: '',
        type: 0
      },
      // 接口返回数据
      menuList: [],
      menuSelect: '1',
      menuDetailList: [],
      // table
      column: [
        {
          title: '#',
          dataIndex: 'index',
          key: 'index',
          width: '10%'
        },
        {
          title: '资料标题',
          dataIndex: 'title',
          key: 'name',
          width: '40%',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '资料类型',
          dataIndex: 'typeName',
          key: 'typeName',
          width: '20%',
          scopedSlots: { customRender: 'typeName' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: '30%',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  filters: {},
  created () {
    this.getMenu(this.menuParam)
  },
  computed: {},
  methods: {
    // 接口请求
    getMenu (p) {
      getMenuList(p).then(response => {
        this.menuList = response.data.list
        this.menuSelect = response.data.list[0].value
        this.searchParam.type = response.data.list[0].value
        this.getList()
      })
    },
    getList () {
      getDocList(this.searchParam).then(response => {
        for (var i = 0;i<response.data.list.length;i++) {
          response.data.list[i].index = i + 1
        }
        this.menuDetailList = response.data.list
      })
    },
    // 操作方法
    plusMenu () {
      this.$router.push({
        path: '/base/doc/edit'
      })
    },
    editItem (id) {
      this.$router.push({
        path: '/base/doc/edit',
        query: {
          id: id
        }
      })
    },
    viewItem (id) {
      this.$router.push({
        path: '/base/doc/view',
        query: {
          id: id
        }
      })
    },
    menuChoose (v) {
      this.searchParam = {
        title: '',
        type: v
      }
      this.getList()
    },
    delItem (id, desc) {
      var self = this
      this.$confirm({
        title: '确认删除',
        content: `是否确认删除当前资料？`,
        cancelText: '取消',
        okText: '确定',
        okType: 'danger',
        onOk () {
          return new Promise((resolve, reject) => {
            deleteDoc({ id: id }).then(response => {
              self.$message.error('删除成功')
              self.getList()
            }).catch((e) => {
              // self.$message.error('删除成功')
            }).finally(() => {
              resolve()
            })
          })
        }
      })
    },
    tableChange (value, col, i) {
      this.menuDetailList[i + (this.nowPage - 1) * this.pagination.pageSize][col] = value
    },
    onSearch (value) {
      // console.log(value);
      this.searchParam.title = value
      this.getList()
    },
    handleTableChange (pagination) {
      // console.log(pagination)
      this.nowPage = pagination.current
    },
    showTab (v) {
      this.viewTab = v
    }
  }
}
</script>
